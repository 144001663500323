@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.switch-to-airtel-root {
   width: 100%;
   padding: 53px 0 58px;
   background-color: colors.$TSurface-100;
   border-bottom: 1px solid colors.$TSurface-500;

   @include media.screen-below(md) {
      border-bottom: none;
      padding: 48px 0;
   }

   .switch-to-airtel-inner {
      margin: 0 auto !important;
      flex-wrap: nowrap;
      gap: 40px;

      @include media.screen-below(sm) {
         gap: 20px;
      }
   }

   .switch-to-airtel-heading {
      @include media.screen-below(sm) {
         align-self: flex-start !important;
         padding-left: 0 !important;
         margin-left: 28px;
      }
   }

   .switch-your-number {
      margin: 0 auto !important;
      padding: 12px;
      flex-wrap: nowrap;
      align-items: center;
      // height: 90px;
      max-width: 540px;
      background: colors.$lilac-100;
      border: 1px solid colors.$lilac-600;
      box-sizing: border-box;
      border-radius: 16px;

      @include media.screen-below(md) {
         width: calc(100% - 65px);
      }

      .switch-image {
         height: 60px;
         width: 60px;
         margin-left: 2px;

         @include media.screen-below(md) {
            margin: 0 0 16px 6px;
         }
      }

      .switch-to-airtel-column {
         width: 100%;
      }

      .switch-text {
         margin-left: 10px;
      }

      .get-sim-text {
         margin-left: 10px;
      }

      .get-started-btn {
         background-color: transparent;
         border: 1px solid colors.$black;
         color: colors.$black;
         box-sizing: border-box;
         margin: 6px 7px 0 0;
         border-radius: 14px;
         padding: 7px 11px !important;
         min-width: auto !important;

         @include media.screen-below(md) {
            margin-left: 9px;
         }
      }
   }
}
